import React, { Component } from "react";
import {Pie} from 'react-chartjs-2';


interface Props {
  chart: any
}



export default class Piechart extends Component<Props, {}> {
  data = () => {
    return {
        labels: [
            '1-50 BDT',
            '51-100 BDT',
            '101-300 BDT',
            '301-up BDT'
        ],
        datasets: [{
            data: [300, 50, 100, 50],
            backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56'
            ],
            hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56'
            ]
        }]
    };
  }

  render() {
    const options = {
      legend: {
        display: true,
        position: 'bottom'
      },
    };
    return (
      <div>
        <Pie 
          height={120} data={this.data()} options={options} />
      </div>
    );
  }
};