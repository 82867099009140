import React, { Component } from 'react'

interface Props {
    addItem: (type: string) => any
}

class AddElments extends React.Component <Props>{
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="automation-title">
                    <h4>Add Component</h4>
                </div>

                <div className="icon_box_section_1 icon_box_center update_box">

                    <div className="text box_style margin_box pt-2" id="text" onClick={() => this.props.addItem('text')}>
                        <img src="assets/sm_text.png" />
                        <p>Text</p>
                    </div>

                    <div className="text box_style margin_box pt-2" id="picture" onClick={() => this.props.addItem('image')}>
                        <img src="assets/sm_picture.png" />
                        <p>Picture</p>
                    </div>
                    <div className="text box_style margin_box pt-2" id="gallery" onClick={() => this.props.addItem('gallery')}>
                        <img src="assets/sm_gallery.png" />
                        <p>Gallery</p>
                    </div>
                    <div className="text box_style margin_box pt-2" id="audio" onClick={() => this.props.addItem('audio')}>
                        <img src="assets/sm_audio.png" />
                        <p>Audio</p>
                    </div>
                    <div className="text box_style margin_box pt-2" id="video" onClick={() => this.props.addItem('video')}>
                        <img src="assets/sm_video.png" />
                        <p>Video</p>
                    </div>
                    <div className="text box_style margin_box pt-2" id="file" onClick={() => this.props.addItem('file')}>
                        <img src="assets/sm_file.png" />
                        <p>File</p>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
export default AddElments
