import React, { Component } from "react"; 
import './login.scss';
import { PrintImage } from './icons'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    loginUser
} from "./actions/authActions";
import ReactLoading from 'react-loading';
import { apiPrefix } from "./../../helper";
import {
    Redirect
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaEye } from 'react-icons/fa';

interface Props {
    history: any
    loginUser: (user: object) => any
}


class Login extends Component<Props, { email: string, password: string, login: boolean, showPassword: boolean, loading: boolean }>  {
    constructor(props: Props) {
        super(props)
        this.state = {
            email: '', //john@adam.me
            password: '', //12345678
            login: false,
            showPassword: true,
            loading: false
        }
    }

    ValidateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        toast.error("Please enter a valid email")
        return (false)
    }

    login = async () => {
        this.setState({loading: true})
        let user = {
            email: this.state.email,
            password: this.state.password
        }
        if (!user.email || !user.password) {
            toast.error("Please input all field")
        } else {
            if (this.ValidateEmail(user.email)) {
                let response = await this.props.loginUser(user)
                console.log("redux response login : ", response)
                if (response.success) {
                    this.setState({ login: true })
                    // toast.success("Login successfull");
                    this.props.history.push('/')
                }else if (response.error) {
                    toast.error(response.error)
                }else{
                    toast.error("Something went wrong. Please try again.")
                }
                this.setState({loading: false})
            }

        }



    };

    _handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.login()
        }
    }

    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.login && <Redirect to={'/'} />}
                <div className="main-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 layout_img">
                                <div className="login">
                                    <div className="login_title">
                                        <img src="https://v2.chatlogy.ai/images/chatlogy-white.png" />
                                    </div>
                                    {this.state.loading && <ReactLoading className="ml-auto mr-auto login-loading" type={"cylon"} color={'white'} height={20} width={'30%'} />}
                                    {this.state.login && <div className="alert alert-success d-flex align-items-center justify-content-center" role="alert">
                                        <FaCheckCircle className="mr-2" />
                                        Login successfull
                                    </div>}
                                    <div className="login_form">
                                        <div className="form-group">
                                            <input type="email"
                                                value={this.state.email}
                                                onChange={e => this.setState({ email: e.target.value })}
                                                className="form-control" id="email" placeholder="Email address" name="email"
                                                onKeyDown={this._handleKeyDown}

                                            />
                                            <div className="user_name_icon">
                                                <svg width="25" height="25" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M35.6023 15.7735L27.4314 1.503C27.033 0.806489 26.3885 0.30924 25.6164 0.102662C25.3297 0.0260922 25.0381 -0.00800931 24.7494 0.00162348C24.2653 0.017725 23.7895 0.154623 23.3591 0.405146L1.48763 13.1376C0.530956 13.6947 -0.00440241 14.7106 2.72718e-05 15.7517C0.00220696 16.2545 0.1313 16.7631 0.397714 17.2287L6.85197 28.5001H3.75007C3.33551 28.5001 3.00005 28.8355 3.00005 29.25C3.00005 29.6645 3.33551 30 3.75007 30H32.25C32.6646 30 33 29.6645 33 29.25C33 28.8355 32.6646 28.5001 32.25 28.5001H19.6773L34.5125 19.8638C35.4696 19.3067 36.0046 18.2908 36 17.25C35.9978 16.7473 35.8688 16.2388 35.6023 15.7735ZM2.24201 14.4331L24.0975 1.70965L18.9155 16.3191C18.7881 16.5761 18.5955 16.7879 18.3567 16.9336C18.1143 17.0809 17.8338 17.1555 17.6242 17.1555C17.6228 17.1555 17.6221 17.1555 17.6221 17.1555L1.71073 15.0131C1.83673 14.7834 2.00084 14.5735 2.24201 14.4331ZM33.758 18.5669L16.6961 28.4999H8.58116L1.72655 16.5288L17.5027 18.649C17.5276 18.6497 17.5532 18.6497 17.5781 18.6497C18.1318 18.6497 18.6694 18.4995 19.1367 18.2146C19.6092 17.9267 19.9907 17.5129 20.2442 17.0148C20.2698 16.9724 20.2911 16.9276 20.3079 16.88L25.6683 1.76745C25.8521 1.89267 26.0147 2.04694 26.1292 2.24712L34.3 16.5184C34.7109 17.2346 34.467 18.1538 33.758 18.5669Z" fill="white" />
                                                </svg>

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type={this.state.showPassword ? "password" : "text"}
                                                value={this.state.password}
                                                onChange={e => { this.setState({ password: e.target.value }) }}
                                                onKeyDown={this._handleKeyDown}
                                                className="form-control" id="password" placeholder="Password" name="password"
                                            />
                                            <div className="password_icon">
                                                <svg width="25" height="25" viewBox="0 0 33 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M28.9363 16.2678V12.3861C28.9363 5.55634 23.2096 0 16.1703 0C9.13104 0 3.40428 5.55634 3.40428 12.3861V16.2678C1.4641 16.6513 0 18.3195 0 20.3132V25.0009C0 25.457 0.380986 25.8267 0.851069 25.8267C1.32115 25.8267 1.70214 25.457 1.70214 25.0009V20.3132C1.70214 18.9472 2.84742 17.836 4.25534 17.836H28.0853C29.4932 17.836 30.6385 18.9472 30.6385 20.3132V38.1493C30.6385 39.2256 29.927 40.1436 28.9363 40.4846V35.5069C28.9363 35.0508 28.5554 34.6812 28.0853 34.6812C27.6152 34.6812 27.2342 35.0508 27.2342 35.5069V40.6265H4.25534C2.84742 40.6265 1.70214 39.5153 1.70214 38.1493V31.6259C1.70214 31.1698 1.32115 30.8002 0.851069 30.8002C0.380986 30.8002 0 31.1698 0 31.6259V38.1493C0 40.4259 1.90892 42.278 4.25534 42.278H28.0853C30.4317 42.278 32.3406 40.4259 32.3406 38.1493V20.3132C32.3406 18.3195 30.8765 16.6513 28.9363 16.2678ZM9.70219 16.1845V12.3861C9.70219 8.92575 12.6038 6.11049 16.1703 6.11049C19.7368 6.11049 22.6384 8.92575 22.6384 12.3861V16.1845H9.70219ZM24.3406 16.1845V12.3861C24.3406 8.01518 20.6753 4.45901 16.1703 4.45901C11.6653 4.45901 8.00005 8.01518 8.00005 12.3861V16.1845H5.10641V12.3861C5.10641 6.46691 10.0695 1.65148 16.1703 1.65148C22.2711 1.65148 27.2342 6.46691 27.2342 12.3861V16.1845H24.3406Z" fill="white" />
                                                    <path d="M0.951202 29.4108C1.20126 29.4108 1.44687 29.3128 1.62373 29.1415C1.8006 28.9706 1.9024 28.7337 1.9024 28.4917C1.9024 28.2501 1.8006 28.0128 1.62373 27.8422C1.44687 27.671 1.20126 27.5726 0.951202 27.5726C0.70114 27.5726 0.455536 27.671 0.278672 27.8422C0.101808 28.0128 0 28.2501 0 28.4917C0 28.7337 0.101808 28.9706 0.278672 29.1415C0.455536 29.3128 0.70114 29.4108 0.951202 29.4108Z" fill="white" />
                                                    <path d="M16.1589 37.6825C17.6159 37.6825 18.8011 36.5957 18.8011 35.2597V30.9712C20.1243 30.168 20.9262 28.8178 20.9262 27.3382C20.9262 24.9337 18.7925 22.9772 16.1703 22.9772C13.5477 22.9772 11.4143 24.9337 11.4143 27.3382C11.4143 28.8071 12.2073 30.1522 13.5167 30.9573V35.2597C13.5167 36.5957 14.7019 37.6825 16.1589 37.6825ZM13.1758 27.3382C13.1758 25.824 14.5189 24.5924 16.1703 24.5924C17.8213 24.5924 19.1648 25.824 19.1648 27.3382C19.1648 28.378 18.5352 29.3166 17.522 29.7882C17.2261 29.9257 17.0397 30.204 17.0397 30.5084V35.2597C17.0397 35.7052 16.6447 36.0673 16.1589 36.0673C15.6731 36.0673 15.2782 35.7052 15.2782 35.2597V30.4977C15.2782 30.1955 15.0941 29.9185 14.801 29.78C13.7985 29.3061 13.1758 28.3705 13.1758 27.3382Z" fill="white" />
                                                </svg>
                                            </div>
                                            <FaEye
                                                onClick={() => this.showPassword()}
                                                className="login-eye" />
                                        </div>
                                        <button className="btn btn-primary login_button"
                                            onClick={this.login}
                                        >log in</button>
                                    </div>

                                    <div className="background_img">
                                        <PrintImage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </React.Fragment>
        );
    }
}


export default connect(null, { loginUser })(Login);
