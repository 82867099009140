import React, { Component } from "react";
import "./QuickReply.scss";
import { connect } from "react-redux";

interface Props {
  item: any;
  allItems: any;
  index: number;
  addOrUpdateFlow: any;
  flows: any;
  quickReplies: [];
}

class QuickReply extends React.Component<
  Props,
  {
    hide_modal_input: boolean;
    flows: any;
    sendBlockClicked: Boolean;
    replyFlowname: string;
    replyTitle: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      hide_modal_input: this.props.item.payload ? true : false,
      flows: this.props.flows,
      sendBlockClicked: this.props.item.payload ? true : false,
      replyFlowname: this.props.item.payload,
      replyTitle: this.props.item.title
    };
  }

  filterFlows = e => {
    let updateList = this.props.flows;
    updateList = updateList.filter(item => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      flows: updateList,
      replyFlowname: e.target.value
    });
  };

  updateReplies = () => {
    let allReplies: any = this.props.quickReplies;
    allReplies[this.props.index] = {
      content_type: "text",
      title: this.state.replyTitle,
      payload: this.state.replyFlowname
    };
    this.props.addOrUpdateFlow(this.props.allItems, null, allReplies)
  };

  deleteReply = () =>{
    let allReplies: any = this.props.quickReplies;
    allReplies.splice(this.props.index, 1)
    this.props.addOrUpdateFlow(this.props.allItems, null, allReplies)
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="main_reply_box"
        >
          <input
            type="text"
            className="quick_subtitle custome_quick"
            id="reply_ID"
            onChange={event =>
              this.setState({ replyTitle: event.target.value })
            }
            value={this.state.replyTitle}
            data-toggle="modal"
            data-target={`#quick_reply ${this.props.index}`}
          />
          <div className="delet_icon_text" id="reply_delete" onClick={() => this.deleteReply()}>
            <i className="fa fa-trash-o" aria-hidden="true"></i>
          </div>
        </div>

        <div
          className="modal fade"
          id={`quick_reply ${this.props.index}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="button_title">
                  <p>Quick Reply:</p>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <input
                    type="text"
                    placeholder="Button Title"
                    className="button_title_input"
                    id="quick_input_buttton"
                    value={this.state.replyTitle}
                    onChange={(e) => this.setState({replyTitle: e.target.value})}
                  />
                </div>
                <div className="button_title_body">
                  <p>When This Button is Pressed</p>
                  <div
                    className={`${
                      this.state.hide_modal_input ? "hide_modal_input " : ""
                    } button_box border_all`}
                    onClick={() =>
                      this.setState({
                        hide_modal_input: true,
                        sendBlockClicked: true,
                        flows: this.props.flows
                      })
                    }
                  >
                    <i
                      className="fa fa-commenting-o padding_top_icon"
                      aria-hidden="true"
                    ></i>
                    <h5>Flow Name</h5>
                  </div>
                  <div
                    className={`${
                      this.state.hide_modal_input ? "" : "button_hide"
                    }`}
                  >
                    {this.state.sendBlockClicked && (
                      <div className="dropdown_bx dropdown-menu">
                        <ul className="">
                          {this.state.flows.map((flow, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                this.setState({
                                  replyFlowname: flow.name
                                })
                              }
                            >
                              {flow.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <input
                      data-toggle="dropdown"
                      // ref={this.sendblockInput}
                      value={this.state.replyFlowname}
                      onChange={e => this.filterFlows(e)}
                      className={
                        this.state.sendBlockClicked
                          ? "hide_input"
                          : "hide_input HIDE"
                      }
                      onFocus={() => this.setState({ flows: this.props.flows })}
                      type="text"
                      placeholder="Flow Name"
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  id="Save_Done"
                  onClick={() => this.updateReplies()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allItems: state.automation.items
});

export default connect(mapStateToProps, null)(QuickReply);
