import React, { Component } from 'react'
import './GalleryNew.scss'
import './../Automation.scss'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

interface Props {
    item: any,
    allItems: any,
    addOrUpdateFlow: any,
    index: number,
    parentIndex: number,
    flows: any
}

class GalleryNew extends React.Component<Props,
    {
        sendBlockClicked: boolean
        phnNmbrClicked: boolean
        urlClicked: boolean
        image: string | null
        title: string | null
        subtitle: string | null,
        buttons: any,
        addbuttonTitle: string | null,
        addbuttonType: string | null,
        addbuttonPayload: string | null,
        addbuttonSendBlock: string | null,
        addbuttonCall: string | null,
        addbuttonUrl: string | null,
        flows: any,
        sendblock: string,
        selectedFlow: string | null,
        btnIndx: number | null
    }
    > {
    sendblockInput: any;
    constructor(props: Props) {
        super(props)
        this.state = {
            sendBlockClicked: false,
            phnNmbrClicked: false,
            urlClicked: false,
            image: this.props.item.image_url ? this.props.item.image_url : "",
            title: this.props.item.title ? this.props.item.title : "",
            subtitle: this.props.item.subtitle ? this.props.item.subtitle : "",
            buttons: this.props.item.buttons ? this.props.item.buttons : [],
            addbuttonTitle: null,
            addbuttonType: null,
            addbuttonPayload: null,
            addbuttonSendBlock: "",
            addbuttonCall: "",
            addbuttonUrl: "",
            flows: this.props.flows,
            sendblock: '',
            selectedFlow: null,
            btnIndx: null,


        }
        this.sendblockInput = React.createRef();
        console.log("all btns",this.props.item.buttons)
    }

    componentDidMount(){
        this.setState({flows: this.props.flows})
    }

    deleteItem = () => {
        let allItems: any = [...this.props.allItems]
        let item = allItems[this.props.parentIndex]
        let generic = item.generic
        generic = generic.filter(item => item.id !== this.props.item.id)
        if (generic.length > 0) {
            let newItem = {
                id: item.id,
                generic: generic
            }
            allItems[this.props.parentIndex] = newItem
        } else {
            allItems.splice(this.props.parentIndex, 1)
        }
        console.log(allItems)
        this.props.addOrUpdateFlow(allItems)
    }

    updateGeneric = (type, btnIndx = null) => {
        console.log("First response: ", this.props.allItems)
        if (type === 'image' && !this.state.image) {
            toast.error("Please add image url or upload an image")
            return
        }
        let allItems: any = [...this.props.allItems]
        let buttons = [...this.state.buttons]
        let item = allItems[this.props.parentIndex]
        let generic = item.generic
        switch (type) {
            case 'image':
                generic[this.props.index].image_url = this.state.image
                break
            case 'deleteImage':
                generic[this.props.index].image_url = null
                break
            case 'title':
                generic[this.props.index].title = this.state.title
                break
            case 'subtitle':
                generic[this.props.index].subtitle = this.state.subtitle
                break
            case 'add_button':
                let button;
                console.log(`addbuttonPayload ${this.state.addbuttonPayload}`)
                console.log(`addbuttonTitle ${this.state.addbuttonTitle}`)
                console.log(`addbuttonType ${this.state.addbuttonType}`)
                if (this.state.addbuttonPayload && this.state.addbuttonTitle && this.state.addbuttonType) {
                    if (this.state.addbuttonType == 'web_url') {
                        button = {
                            type: 'web_url',
                            url: this.state.addbuttonPayload,
                            title: this.state.addbuttonTitle,
                        }
                    } else if (this.state.addbuttonType == 'phone_number') {
                        button = {
                            type: 'phone_number',
                            title: this.state.addbuttonTitle,
                            payload: this.state.addbuttonPayload,
                        }
                    } else if (this.state.addbuttonType == 'postback') {
                        button = {
                            type: 'postback',
                            title: this.state.addbuttonTitle,
                            payload: this.state.addbuttonPayload,
                        }
                        if (!this.state.selectedFlow) {
                            this.props.addOrUpdateFlow(null, this.state.addbuttonSendBlock)
                        }
                    }
                    if (this.state.btnIndx != null) {
                        buttons[this.state.btnIndx] = button
                        this.setState({btnIndx: null})
                        console.log("Btn indx",this.state.btnIndx)
                    } else {
                        buttons.push(button)
                        console.log("No edit btn indx")
                    }
                    this.setState({ buttons, selectedFlow: null })
                    generic[this.props.index].buttons = buttons
                    console.log("New buttons", buttons)
                    console.log("selected btn indx: ", this.state.btnIndx)
                } else {
                    toast.error("Please add button title and payload")
                }
                break
            case 'delete_button':
                console.log("delete btn index ", btnIndx)
                buttons.splice(btnIndx, 1)
                generic[this.props.index].buttons = buttons
                this.setState({ buttons })
                console.log("New buttons", buttons)
                break
        }
        allItems[this.props.parentIndex].generic = generic
        console.log("all items after updating generic", allItems)
        this.props.addOrUpdateFlow(allItems)

    }

    filterFlows = (e) => {
        let updateList = this.props.flows;
        updateList = updateList.filter(item => {
            return item.name.toLowerCase().search(
                e.target.value.toLowerCase()
            ) !== -1;
        });

        this.setState({
            flows: updateList,
            addbuttonType: 'postback',
            // addbuttonPayload: e.target.value,
            addbuttonSendBlock: e.target.value,
            addbuttonCall: null,
            addbuttonUrl: null
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="G_main_gallery">

                    <div className="main_gallery_section" >
                        <div className="text box_style gallery subGalleryDiv" id="main_box_gallery">
                            <div className="box_details">
                                <div className="img_box" data-toggle="modal" data-target={"#subGallery" + this.props.item.id}>
                                    {/*<i className={`fa fa-picture-o ${this.props.item.image_url ? 'gallery_hide_view' : 'gallery_hide'}`} aria-hidden="true"></i>*/}
                                    <img className={` ${this.props.item.image_url ? 'gallery_hide_view' : 'gallery_hide'}`} src="assets/Image_1.png" />
                                    <p className={`img_box_upload_text ${this.props.item.image_url ? 'gallery_hide_view' : 'gallery_hide'}`}>Upload image</p>
                                    <img src={this.props.item.image_url} id="gallery_img_1" className={`main_gallery_img ${this.props.item.image_url ? null : 'opacity hide'}`} />
                                    {/*<input type="file" />*/} </div>
                                <div className="box_input" />
                                <input
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                    type="text" className="box_input"
                                    placeholder="Enter your title"
                                    onBlur={() => this.updateGeneric('title')}
                                />
                                <input
                                    value={this.state.subtitle}
                                    onChange={(e) => this.setState({ subtitle: e.target.value })}
                                    type="text" className="box_input box_input_2"
                                    placeholder="Enter your Subtitle"
                                    onBlur={() => this.updateGeneric('subtitle')}
                                />
                            </div>
                            {this.state.buttons.length > 0 && this.state.buttons.map((button, index) => (
                                <div className="box_button" key={index}>
                                    <button type="button"
                                        className="delete_box_flex box_button_input" id="sub_box_button_input_1"
                                    >

                                        <span
                                            onClick={() => this.setState({
                                                btnIndx: index,
                                                addbuttonTitle: button.title,
                                                addbuttonCall: button.type == "phone_number" ? button.payload : null,
                                                addbuttonUrl: button.type == "web_url" ? button.url : null,
                                                addbuttonSendBlock: button.type == "postback" ? button.payload : null,
                                                addbuttonPayload: button.payload ? button.payload : button.url,
                                                addbuttonType: button.type,
                                                sendBlockClicked: button.type == "postback" ? true : false
                                            })}
                                            className="modal_button" data-toggle={"modal"} data-target={"#gallery_modal" + this.props.item.id}>{button.title}</span>
                                        <div className="sub_delete_box" onClick={() => this.updateGeneric('delete_button', index)}>
                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                        </div>
                                    </button>
                                </div>
                            ))}
                            {this.state.buttons.length < 3 && <div className="box_button"
                                onClick={() => {
                                    this.setState({
                                        addbuttonTitle: "",
                                        addbuttonCall: "",
                                        addbuttonUrl: "",
                                        addbuttonSendBlock: "",
                                        addbuttonPayload: ""
                                    })
                                }}>
                                <button type="button" className="delete_box_flex box_button_input" id="sub_box_button_input_1" onClick={() => this.setState({btnIndx: null})}>
                                    <span className="modal_button" data-toggle={"modal"} data-target={"#gallery_modal" + this.props.item.id}>+Add Button</span>
                                </button>

                            </div>}

                        </div>
                        <div className="delet_icon_gallery" id="gallery_delete" onClick={() => this.deleteItem()}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div className="modal fade" id={"gallery_modal" + this.props.item.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Button</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="button_title">
                                        <p>button Title:</p>
                                        <input value={this.state.addbuttonTitle} type="text" className="button_title_input" id="buttton" onChange={(e) => this.setState({ addbuttonTitle: e.target.value })} />
                                    </div>
                                    <div className="button_title_body">
                                        <p>When This Button is Pressed</p>
                                        <div className={`${this.state.sendBlockClicked || this.state.addbuttonSendBlock ? 'HIDE ' : ''} button_box border_all`} onClick={() => { this.setState({ sendBlockClicked: true }); this.sendblockInput.focus(); }}>
                                            <i className="fa fa-commenting-o padding_top_icon" aria-hidden="true"></i>
                                            <h5>Flow Name</h5>
                                        </div>

                                        {this.state.sendBlockClicked && <div className="dropdown_bx dropdown-menu">
                                            <ul className="">
                                                {this.state.flows.map((flow, index) => (
                                                    <li key={index} onClick={() => this.setState({
                                                        selectedFlow: flow.slug,
                                                        addbuttonSendBlock: flow.name,
                                                        addbuttonType: 'postback',
                                                        addbuttonPayload: flow.slug
                                                    })}>{flow.name}</li>
                                                ))}
                                            </ul>
                                        </div>}
                                        <input data-toggle="dropdown"
                                            // ref={this.sendblockInput}
                                            ref={(input) => { this.sendblockInput = input; }}
                                            value={this.state.addbuttonSendBlock}
                                            onBlur={() => this.setState({flows: this.props.flows})}
                                            onChange={(e) => this.filterFlows(e)}
                                            className={this.state.sendBlockClicked || this.state.addbuttonSendBlock ? "hide_input" : "hide_input HIDE"}
                                            type="text"
                                            placeholder="Flow Name"
                                            autoFocus
                                        />


                                        <div className="hide_modal_input button_hide" >
                                            <input className="hide_input" type="text" placeholder="Flow Name" />
                                        </div>
                                        <div className={`${this.state.phnNmbrClicked || this.state.addbuttonCall ? 'HIDE ' : ''} button_box border_all`} onClick={() => this.setState({ phnNmbrClicked: true })}>
                                            <i className="fa fa-phone padding_top_icon" aria-hidden="true"></i>
                                            <h5>Call</h5>
                                        </div>
                                        <div className="hide_modal_input button_hide" >
                                            <input className="hide_input" type="number" placeholder="Phone Number" />
                                        </div>

                                        <input
                                            value={this.state.addbuttonCall}
                                            onChange={(e) => this.setState({
                                                addbuttonType: 'phone_number',
                                                addbuttonPayload: e.target.value,
                                                addbuttonCall: e.target.value,
                                                addbuttonUrl: null,
                                                addbuttonSendBlock: null
                                            })}
                                            className={this.state.phnNmbrClicked || this.state.addbuttonCall ? "hide_input" : "hide_input HIDE"}
                                            type="number"
                                            placeholder="number"
                                        />

                                        <div className={`${this.state.urlClicked || this.state.addbuttonCall ? 'HIDE ' : ''} button_box border_all`} onClick={() => this.setState({ urlClicked: true })}>
                                            <i className="fa fa-link padding_top_icon" aria-hidden="true"></i>
                                            <h5>Url</h5>
                                        </div>
                                        <div className="hide_modal_input button_hide" >
                                            <input className="hide_input" type="number" placeholder="Phone Number" />
                                        </div>

                                        <input
                                            value={this.state.addbuttonUrl}
                                            onChange={(e) => this.setState({
                                                addbuttonType: 'web_url',
                                                addbuttonPayload: e.target.value,
                                                addbuttonCall: null,
                                                addbuttonUrl: e.target.value,
                                                addbuttonSendBlock: null
                                            })}
                                            className={this.state.urlClicked || this.state.addbuttonUrl ? "hide_input" : "hide_input HIDE"}
                                            type="text"
                                            placeholder="url"
                                        />

                                        {/* <div className="button_box border_all" id="url" data-toggle="modal" data-target="#urlModal">
                                            <i className="fa fa-link padding_top_icon" aria-hidden="true"></i>
                                            <h5>Url</h5>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">

                                    <button type="button" className="btn btn-secondary pop_btn" data-dismiss="modal">Close</button>

                                    <button type="button" className="btn btn-primary pop_btn" data-dismiss="modal" onClick={() => this.updateGeneric('add_button')}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade urlmodal" id="urlModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog " role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="">Open Website</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="button_title">
                                        <p>Website address:</p>
                                        <input value={this.state.addbuttonUrl}
                                            onChange={(e) => this.setState({
                                                addbuttonPayload: e.target.value,
                                                addbuttonUrl: e.target.value,
                                                addbuttonSendBlock: null,
                                                addbuttonCall: null,
                                                addbuttonType: "web_url",
                                            })}
                                            type="text" className="button_title_input" id=""
                                        />
                                    </div>
                                    {/* <div className="button_title_body">
                                        <div className="webview_title">
                                            <p>Webview Size:</p>
                                        </div>
                                        <div className="button_box select_box">
                                            <select name="cars">
                                                <option>Native</option>
                                                <option>full - 100%</option>
                                                <option>Medium - 75%</option>
                                                <option>Compact - 50%</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary pop_btn" data-dismiss="modal">Delete</button>
                                    <button type="button" className="btn btn-primary pop_btn" data-dismiss="modal">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id={"subGallery" + this.props.item.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog position_gallery" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Upload image
                                </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <input value={this.state.image} type="text" className="form-control modal_input" placeholder="Enter image Url" onChange={(e) => this.setState({ image: e.target.value })} />
                                    </div>
                                    <p className="or">OR</p>
                                    <div className="video_upload_file modal_input">
                                        <input type="file" className="Modal_fileuploader" />
                                        <p>Upload File</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.updateGeneric('deleteImage')}>Delete</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.updateGeneric('image')}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(GalleryNew);
