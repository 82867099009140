import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { apiPrefix, baseTitle } from "./../../helper";
import { Helmet } from "react-helmet";
import LineChart from "./../linechart/LineChart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "./../login/actions/authActions";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
  inputStyle: any;
  onChangeSelectedFlow: any;
  flows: any;
  addWit: any;
  id: any;
  text: string
}

class FlowInput extends Component<
  Props,
  {
    chart: [];
    allFlows: [];
    flows: [];
    selectedFlowName: string;
    value: string;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: [],
      allFlows: this.props.flows,
      flows: this.props.flows,
      selectedFlowName: "",
      value: ""
    };
  }

  filterFlows = e => {
    let updateList: any = this.state.allFlows;
    updateList = updateList.filter(item => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      flows: updateList,
      value: e.target.value
    });
  };

  addWit = () => {
    console.log("flow input")
    this.props.addWit(this.state.value, this.props.id, this.props.text);
    this.setState({ selectedFlowName: "", value: "" });
  };

  render() {
    return (
      <div className="_select_box align-items-center">
        <div className="dropdown_bx dropdown-menu" style={{ width: "25%" }}>
          <ul className="">
            {this.state.flows.map((flow: any, index) => (
              <li
                key={index}
                onClick={() => {
                  this.setState({
                    selectedFlowName: flow.name,
                    value: flow.name
                  });
                }}
              >
                {flow.name}
              </li>
            ))}
          </ul>
        </div>
        <input
          style={JSON.parse(this.props.inputStyle)}
          data-toggle="dropdown"
          // ref={this.sendblockInput}
          value={this.state.value}

          // onChange={e => {this.filterFlows(e); this.setState({value: e.target.value})}}
          className={"input_margin_top_0 "}

          onChange={e => {
            this.filterFlows(e);
            this.setState({ value: e.target.value });
          }}
          type="text"
          placeholder="Select Flow"
          autoFocus
        />
        <div className="train_button">
          <img src="assets/retain_chat.png" />
          <p className="" onClick={() => this.addWit()}>
            Train
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(FlowInput);
